import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Collapse,
  Button,
  Progress,
} from "reactstrap";
// componentes e utils
import ModalEditVehicle from "../vehicles/ModalEditVehicle";
import PieChart from "./PieChart";
import ModalViewStatus from "./modalViewStatus";
import ModalListAllParticipants from "./modalListAllParticipants";
import { getTransferDashboard } from "../../../utils/services/transfer";
import {
  createVehicleStatus,
  getAllVehiclesToDashboard,
  getTotalVehicleByStatus,
} from "../../../utils/services/vehicles";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";
import Pagination from "../../../components/Paginationv3";
import useDebounce from "../../../utils/hooks/useDebounce";
import RowTable from "./rowTable";
import { useLocation } from "react-router-dom";

export default function DashboardLogistics(props) {
  // collapse
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  // paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const [dashboardData, setDashboardData] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [editData, setEditData] = useState({});
  // modal
  const [modalEditVehicle, setModalEditVehicle] = useState(false);
  const [modalViewStatus, setModalViewStatus] = useState(false);
  const [modalListAllParticipants, setModalListAllParticipants] =
    useState(false);
  // status selecionado
  const [statusSelected, setStatusSelected] = useState("");
  // contador
  const [allAccountant, setAllAccountant] = useState([]);

  // filtros
  const [activeFilter, setActiveFilter] = useState("description");
  const columnsFilter = [
    { name: "Nome", value: "description", type: "text" },
    { name: "Origem", value: "origin", type: "text" },
    { name: "Destino", value: "destination", type: "text" },
    { name: "Data Saída", value: "departureDateTime", type: "date" },
    { name: "Data Chegada", value: "arrivalDateTime", type: "date" },
  ];
  const location = useLocation();
  // id do evento
  const idEvent = location.state.idEvent;

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  const topics = [
    {
      name: "Não Iniciado",
      icon: "mdi mdi-bus-clock",
    },
    {
      name: "Embarcando",
      icon: "mdi mdi-clock-start",
    },
    {
      name: "Em Trânsito",
      icon: "bx bx-transfer",
    },
    {
      name: "Desembarcando",
      icon: "mdi mdi-bus-alert",
    },
    {
      name: "Finalizado",
      icon: "bx bx-check-circle",
    },
    {
      name: "Cancelado",
      icon: "bx bx-block",
    },
  ];

  function getAmountStatus() {
    getTotalVehicleByStatus(idEvent).then((res) => {
      // console.log("ok", res);
      if (res.data.message === "total_vehicle_by_status") {
        setAllAccountant(res.data.findByIdData.data);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  const reverseDate = () => {
    const reverse = debouncedSearch
      .split("/")
      .reverse()
      .join("/")
      .replace(/\//g, "-");
    return reverse;
  };

  function treatmentSearch() {
    if (
      columnsFilter.filter((item) => item.value === activeFilter)[0].type ===
      "date"
    ) {
      return reverseDate();
    }
    return debouncedSearch;
  }

  function getAllVehicles() {
    getAllVehiclesToDashboard(
      idEvent,
      currentIndex,
      itemsPerPage,
      treatmentSearch(),
      activeFilter
    ).then((res) => {
      if (res.data.message === "FOUND") {
        setVehicles(res.data.findAll.data);
        setCountData(res.data.findAll.count);
      }
    });
  }

  function toggleEditVehicle() {
    setModalEditVehicle(!modalEditVehicle);
    if (modalEditVehicle) {
      getAllVehicles();
    }
  }

  async function changeStatusVehicle(newStatus, item, notes) {
    const data = {
      eventId: idEvent,
      vehicleId: item.id,
      previousStatus: item.status,
      newStatus: newStatus,
      notes: notes || "",
    };

    await createVehicleStatus(data).then((res) => {
      // if (res.data.message === "created") {
      getAllVehicles();
      // }
    });
  }

  useEffect(() => {
    getTransferDashboard(idEvent).then((res) => {
      setDashboardData(res.data);
    });

    getAmountStatus();
  }, []);

  useEffect(() => {
    getAllVehicles();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <>
      {modalEditVehicle && (
        <ModalEditVehicle
          isOpen={modalEditVehicle}
          toggle={toggleEditVehicle}
          idEvent={idEvent}
          data={editData}
          getAllVehicles={getAllVehicles}
        />
      )}

      {modalViewStatus && (
        <ModalViewStatus
          isOpen={modalViewStatus}
          toggle={() => setModalViewStatus(false)}
          statusSelected={statusSelected}
          idEvent={idEvent}
        />
      )}

      {modalListAllParticipants && (
        <ModalListAllParticipants
          isOpen={modalListAllParticipants}
          toggle={() => setModalListAllParticipants(false)}
          statusSelected={statusSelected}
          idEvent={idEvent}
        />
      )}

      <Card>
        <CardBody>
          <CardTitle>Dashboard Transfer</CardTitle>
          <Row className="my-4">
            <Col className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  getTransferDashboard(idEvent).then((res) => {
                    setDashboardData(res.data);
                  });

                  getAmountStatus();
                }}
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
              >
                <i class="fa-solid fa-arrows-rotate"></i> Atualizar Dashboard
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={12} className="logistic-all-topics">
                  <div className="logistic-topic card-shadow">
                    <h4 className="logistics-title-topic">
                      Total de Participantes
                    </h4>
                    <a>
                      {dashboardData ? dashboardData.totalParticipants : ""}
                    </a>
                  </div>

                  <div className="logistic-topic card-shadow">
                    <h4 className="logistics-title-topic">Total de Veículos</h4>
                    <a>{dashboardData ? dashboardData.totalVehicles : ""}</a>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: 20, justifyContent: "center" }}>
                <Col md={12}>
                  <Card className="card-shadow">
                    <CardBody>
                      <PieChart
                        totalCapacity={
                          dashboardData ? dashboardData.totalCapacity : 0
                        }
                        totalOccupied={
                          dashboardData ? dashboardData.totalOccupied : 0
                        }
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <CardTitle>Veículos pos Status</CardTitle>
              <div
                className="logistic-all-topics"
                style={{ flexWrap: "wrap", justifyContent: "flex-start" }}
              >
                {topics.map((e, i) => (
                  <div
                    className="logistic-topic logistic-right-topic card-shadow"
                    key={i}
                    onClick={() =>
                      setStatusSelected(e.name) > setModalViewStatus(true)
                    }
                  >
                    <i className={`${e.icon} icon-topic`} />

                    <div className="div-data-right-topic">
                      <h4 className="logistics-title-topic">{e.name}</h4>
                      <a>
                        {allAccountant.find((item) => item.status === e.name)
                          ? allAccountant.find((item) => item.status === e.name)
                              .totalVehicles
                          : 0}
                      </a>
                    </div>
                  </div>
                ))}
              </div>

              <CardTitle>Participantes por Status</CardTitle>
              <div
                className="logistic-topic logistic-right-topic card-shadow"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    margin: "0 10px",
                  }}
                  onClick={() =>
                    setStatusSelected("Embarcado") >
                    setModalListAllParticipants(true)
                  }
                >
                  <a>20</a>
                  <a>Embarcado</a>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <a>15</a>
                  <div className="div-all-progress">
                    <div className="div-current-progress" />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    margin: "0 10px",
                    width: "30%",
                  }}
                  onClick={() =>
                    setStatusSelected("Geral") >
                    setModalListAllParticipants(true)
                  }
                >
                  <a>200</a>
                  <a>Não Embarcou / Desembarcado</a>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <CardTitle>Veículos</CardTitle>
              <div>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                    width: "50px",
                  }}
                >
                  <SectionComponent
                    sectionName="Filtros"
                    setIsOpen={setCollapseIsOpen}
                    isOpen={collapseIsOpen}
                  />
                </Row>
                <Collapse isOpen={collapseIsOpen}>
                  <SearchFilters
                    columns={columnsFilter}
                    activeFilter={activeFilter}
                    setActiveFilterHandle={setActiveFilterHandle}
                  />
                </Collapse>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  margin: "10px 0",
                }}
              >
                <form
                  className="app-search d-none d-lg-block events-input-search"
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "100%" }}
                >
                  <div className="position-relative">
                    <input
                      type={
                        activeFilter === "departureDateTime" ||
                        activeFilter === "arrivalDateTime"
                          ? "date"
                          : "text"
                      }
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                      // style={{ width: "100%" }}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>

                <div className="mobile-btn-section" style={{ width: "100%" }}>
                  <Row className="mobile-btns-top">
                    <form
                      className="app-search"
                      style={{ padding: "0px" }}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="position-relative">
                        <input
                          type={
                            activeFilter === "departureDateTime" ||
                            activeFilter === "arrivalDateTime"
                              ? "date"
                              : "text"
                          }
                          value={search}
                          className="form-control"
                          placeholder="Pesquisar..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="bx bx-search-alt" />
                      </div>
                    </form>
                  </Row>
                </div>
              </div>

              <div className="table-responsive" style={{ margin: "20px 0" }}>
                <Table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Nome</th>
                      <th>Status</th>
                      <th>Ações</th>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    {vehicles?.map((e, i) => (
                      <RowTable
                        data={e}
                        setEditData={() => setEditData(e)}
                        setModalEditVehicle={() => toggleEditVehicle()}
                        changeStatus={changeStatusVehicle}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
              <div
                className="bottom-navigation"
                style={{ justifyContent: "flex-end" }}
              >
                <Pagination
                  data={[]}
                  search={search}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  countData={countData}
                  maxItemPerPage={itemsPerPage}
                  setMaxItemPerPage={setItemsPerPage}
                  maxPageButtonsToShow={3}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
