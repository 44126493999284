import React, { useState, useEffect } from "react";
// grafico
import Chart from "react-apexcharts";
// moment
import moment from "moment";
import { split } from "lodash";

function ColumnChart(props) {
  const { dataByDay } = props;
  // lista de dias
  const [allDays, setAllDays] = useState([]);
  // lista de dados
  const [employee, setEmployee] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [guest, setGuest] = useState([]);

  const opcoes = {
    legend: {
      show: true,
      position: "top",
    },
    xaxis: {
      categories: allDays,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
        endingShape: "rounded",
      },
    },
  };

  const series = [
    {
      name: "Funcionário",
      data: employee,
    },
    {
      name: "Revendedor",
      data: dealer,
    },
    {
      name: "Convidado",
      data: guest,
    },
  ];

  function getDays() {
    if (dataByDay.length > 0) {
      var listDays = [];

      // pegar todas as datas do objeto sem repetir
      dataByDay.map((day) => {
        const dayCurrent = moment(day.data_associacao).format("MM/DD/YYYY");

        var search = listDays.find((e) => e === dayCurrent);

        if (!search) {
          listDays.push(dayCurrent);
        }
      });

      // ordernar dadas do array por ordem crescente
      listDays.sort(function (dataA, dataB) {
        if (new Date(dataA) < new Date(dataB)) return -1;
        if (new Date(dataA) > new Date(dataB)) return 1;
        return 0;
      });

      // gerar dados dos grupos com base na ordem dos dias
      formatData(listDays, "funcionário", setEmployee);
      formatData(listDays, "revendedor", setDealer);
      formatData(listDays, "convidado", setGuest);

      formatDate(listDays);
    }
  }

  function formatData(listDays, filter, setData) {
    // filtrar array pelo nome do grupo
    var dataFilter = dataByDay.filter((e) => e.grupo === filter);

    var dataValueFilter = [];

    // comparar os dias da lista de dias únicos com os de cada objeto para pegar o valor do objeto daquele dia
    listDays.map((day) => {
      var search = dataFilter.find(
        (e) => moment(e.data_associacao).format("MM/DD/YYYY") === day
      );

      if (search) {
        dataValueFilter.push(search.total_participantes);
      } else dataValueFilter.push("0");
    });

    setData(dataValueFilter);
  }

  function formatDate(listDays) {
    var newFormt = listDays.map((e) => {
      return moment(new Date(e)).format("DD/MM");
    });

    setAllDays(newFormt);
  }

  useEffect(() => {
    getDays();
  }, [dataByDay]);

  return (
    <div>
      <Chart options={opcoes} series={series} type="bar" height="400" />
    </div>
  );
}

export default ColumnChart;
