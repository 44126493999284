import React, { useState, useEffect } from "react";
// grafico
import Chart from "react-apexcharts";
// moment
import moment from "moment";

function LineChart(props) {
  const { dataByDay, dataEvolution } = props;
  // lista de dias
  const [allDays, setAllDays] = useState([]);
  const [allData, setAllData] = useState([]);
  // lista de dados
  const [employee, setEmployee] = useState([]);
  const [dealer, setDealer] = useState([]);
  const [guest, setGuest] = useState([]);

  const opcoes = {
    legend: {
      show: true,
      position: "top",
    },
    xaxis: {
      categories: allDays,
    },
    colors: ["#34C759", "#FFC107", "#8E24AA"],
  };

  const series = [
    // {
    //   name: "Vendas",
    //   data: allData,
    // },
    {
      name: "Funcionário",
      data: employee,
    },
    {
      name: "Revendedor",
      data: dealer,
    },
    {
      name: "Convidado",
      data: guest,
    },
  ];

  function getDays() {
    if (dataEvolution.length > 0) {
      var listDays = [];

      // pegar todas as datas do objeto sem repetir
      dataEvolution.map((day) => {
        const dayCurrent = moment(day.data_criacao).format("MM/DD/YYYY");

        var search = listDays.find((e) => e === dayCurrent);

        if (!search) {
          listDays.push(dayCurrent);
        }
      });

      // ordernar dadas do array por ordem crescente
      listDays.sort(function (dataA, dataB) {
        if (new Date(dataA) < new Date(dataB)) return -1;
        if (new Date(dataA) > new Date(dataB)) return 1;
        return 0;
      });

      // gerar dados dos grupos com base na ordem dos dias
      formatData(listDays, "funcionário", setEmployee);
      formatData(listDays, "revendedor", setDealer);
      formatData(listDays, "convidado", setGuest);

      formatDate(listDays);
    }
  }

  function formatData(listDays, filter, setData) {
    // filtrar array pelo nome do grupo
    var dataFilter = dataEvolution.filter((e) => e.grupo === filter);

    var dataValueFilter = [];

    var previous = "0";

    // comparar os dias da lista de dias únicos com os de cada objeto para pegar o valor do objeto daquele dia
    listDays.map((day) => {
      var search = dataFilter.find(
        (e) => moment(e.data_criacao).format("MM/DD/YYYY") === day
      );

      var totalSet = previous;

      // se achar o objeto, da push no valor dele
      if (search) {
        totalSet = search.total_participantes;
      }

      // atualizar o valor de previus para caso no próximo não ache o objeto, se manter o valor anterior para seguir a ideia do gráfico crescente
      previous = totalSet;

      dataValueFilter.push(totalSet);
    });

    setData(dataValueFilter);
  }

  function formatDate(listDays) {
    var newFormt = listDays.map((e) => {
      return moment(new Date(e)).format("DD/MM");
    });

    setAllDays(newFormt);
  }

  useEffect(() => {
    getDays();
  }, [dataEvolution]);

  return (
    <div>
      <Chart options={opcoes} series={series} type="line" height="350" />
    </div>
  );
}

export default LineChart;
