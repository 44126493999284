import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
// componentes
import ColumnChart from "./columnChart";
import LineChart from "./lineChart";
// service
import {
  getDays,
  getGeneral,
  getEvolution,
} from "../../../utils/services/dashboardVibra";

export default function DashboardVibra() {
  const [dataByDay, setDataByDay] = useState([]);
  const [dataEvolution, setDataEvolution] = useState([]);
  const [generalData, setGeneralData] = useState(null);

  function getDaysFunc() {
    getDays()
      .then((res) => {
        // console.log("ok by day", res)
        if (res[0]) setDataByDay(res);
      })
      .catch((error) => console.log("erro", error));
  }

  function getGeneralFunc() {
    getGeneral()
      .then((res) => {
        // console.log("ok geral", res);
        if (res.total) {
          setGeneralData(res.geral);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  function getRegistered(name) {
    var search = generalData.find((e) => e.grupo === name);

    var totalReturn = "-";

    if (search) {
      totalReturn = search.total_participantes;
    }

    return totalReturn;
  }

  function getNeedRegister(name) {
    var search = generalData.find((e) => e.grupo === name);

    var totalReturn = "-";

    if (search) {
      if (name === "revendedor") {
        console.log(search)
        totalReturn = `${
          Number(search.total_geral) - Number(search.total_revendedores)
        } ( ${(100 - search.percentual_revendedores).toFixed(2)} % )`;
      } else {
        totalReturn = `${
          Number(search.total_geral) - Number(search.total_participantes)
        } ( ${(100 - search.percentual_participantes).toFixed(2)} % )`;
      }
    }

    return totalReturn;
  }

  function getEvolutionFunc() {
    getEvolution()
      .then((res) => {
        // console.log("ok evolution", res);
        if (res[0]) setDataEvolution(res);
      })
      .catch((error) => console.log("erro evolution", error));
  }

  useEffect(() => {
    getDaysFunc();
    getGeneralFunc();
    getEvolutionFunc();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle>Totalizadores</CardTitle>

          <Row style={{ marginTop: 20 }}>
            <h5>Cadastrados</h5>
          </Row>

          <Row style={{ margin: "20px 0" }}>
            <Col
              md={12}
              className="logistic-all-topics"
              style={{ flexWrap: "wrap" }}
            >
              <div className="logistic-topic card-shadow">
                <h4 className="logistics-title-topic">Time Vibra</h4>
                <a>{generalData ? getRegistered("funcionário") : "-"}</a>
              </div>

              <div className="logistic-topic card-shadow">
                <h4 className="logistics-title-topic">Revendedor</h4>
                <a>{generalData ? getRegistered("revendedor") : "-"}</a>
              </div>

              <div className="logistic-topic card-shadow">
                <h4 className="logistics-title-topic">Convidado</h4>
                <a>{generalData ? getRegistered("convidado") : "-"}</a>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 50 }}>
            <h5>Faltam se Cadastrar</h5>
          </Row>

          <Row style={{ margin: "20px 0" }}>
            <Col
              md={12}
              className="logistic-all-topics"
              style={{ flexWrap: "wrap" }}
            >
              <div className="logistic-topic card-shadow">
                <h4 className="logistics-title-topic">Time Vibra</h4>
                <a>{generalData ? getNeedRegister("funcionário") : "-"}</a>
              </div>

              <div className="logistic-topic card-shadow">
                <h4 className="logistics-title-topic">Revendedor</h4>
                <a>{generalData ? getNeedRegister("revendedor") : "-"}</a>
              </div>

              <div className="logistic-topic card-shadow">
                <h4 className="logistics-title-topic">Convidado</h4>
                <a>{generalData ? getNeedRegister("convidado") : "-"}</a>
              </div>
            </Col>
          </Row>

          <Row style={{ margin: "20px 0" }}>
            <Col md={12}>
              <ColumnChart dataByDay={dataByDay} />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <LineChart dataByDay={dataByDay} dataEvolution={dataEvolution} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
