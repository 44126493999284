import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
// service
import { getVehicleByStatus } from "../../../utils/services/vehicles";

export default function ModalViewStatus(props) {
  const { isOpen, toggle, statusSelected, idEvent } = props;
  // exibir info
  const [open, setOpen] = useState(false);

  function getListVehicles() {
    getVehicleByStatus(idEvent, statusSelected)
      .then((res) => {
        console.log("ok", res);
      })
      .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    if (statusSelected) {
      getListVehicles();
    }
  }, [statusSelected]);

  return (
    <>
      <Modal isOpen={isOpen} centered size="lg">
        <ModalHeader toggle={toggle}>Visualizar Veículos</ModalHeader>
        <ModalBody>
          <div className="table-responsive" style={{ margin: "20px 0" }}>
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome</th>
                  <th>Status</th>
                  <th>Ações</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>veiculo 1</td>
                  <td>aguardando</td>
                  <td>
                    <i
                      className="mdi mdi-eye"
                      style={{
                        color: "blue",
                        fontSize: 17,
                        cursor: "pointer",
                        marginRight: 5,
                      }}
                      onClick={() => setOpen(!open)}
                    />
                  </td>
                  <td></td>
                </tr>

                {open && (
                  <tr>
                    <td style={{ backgroundColor: "white" }}>De: inicio</td>
                    <td style={{ backgroundColor: "white" }}>Para: final</td>
                    <td style={{ backgroundColor: "white" }}>Capacidade: 10</td>
                    <td style={{ backgroundColor: "white" }}>
                      Participantes no Veículo: 9
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
