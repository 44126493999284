import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Row,
  Collapse,
} from "reactstrap";
// componentes
import Pagination from "../../../components/Paginationv3";
import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";
import ModalViewParticipant from "./modalViewParticipants";
// service
import { getParticipantsByStatus } from "../../../utils/services/vehicles";
import { getParticipantById } from "../../../utils/services/participants";

export default function ModalListAllParticipants(props) {
  const { isOpen, toggle, statusSelected, idEvent } = props;
  // modal
  const [modalViewParticipant, setModalViewParticipant] = useState(false);
  // participante selecionado
  const [participant, setParticipant] = useState([]);
  // listagem
  const [dataList, setDataList] = useState([]);
  // paginação e filtro
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countData, setCountData] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const [activeFilter, setActiveFilter] = useState("nome");
  const columnsFilter = [
    { name: "Nome", value: "nome", type: "text" },
    { name: "Email", value: "email", type: "text" },
  ];

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function getOneParticipant(part) {
    getParticipantById(idEvent, part, currentIndex, itemsPerPage).then(
      (res) => {
        if (res.message === "users") {
          setModalViewParticipant(true);
          setParticipant(res.data.data);
        }
      }
    );
  }

  function getParticipantsByStatusFunc() {
    getParticipantsByStatus({
      eventId: idEvent,
      status: statusSelected,
      offset: currentIndex,
      limit: itemsPerPage,
      search: debouncedSearch,
      columnSearch: activeFilter,
    }).then((res) => {
      if (res.data.message === "participants_by_status") {
        setDataList(res.data.findByIdData.data);
      }
    });
    // .catch((error) => console.log("erro", error));
  }

  useEffect(() => {
    getParticipantsByStatusFunc();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <>
      {modalViewParticipant && (
        <ModalViewParticipant
          isOpen={modalViewParticipant}
          toggle={() => {
            setModalViewParticipant(false);
          }}
          participant={participant[0]}
          idEvent={idEvent}
        />
      )}

      <Modal isOpen={isOpen} centered autoFocus size="lg">
        <ModalHeader toggle={toggle}>Participantes por veículo</ModalHeader>
        <div style={{ marginLeft: "15px", width: "95%" }}>
          <Row
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              width: "50px",
            }}
          >
            <SectionComponent
              sectionName="Filtros"
              setIsOpen={setCollapseIsOpen}
              isOpen={collapseIsOpen}
            />
          </Row>
          <Collapse isOpen={collapseIsOpen}>
            <SearchFilters
              columns={columnsFilter}
              activeFilter={activeFilter}
              setActiveFilterHandle={setActiveFilterHandle}
            />
          </Collapse>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // margin: "px 0",
          }}
        >
          <form
            className="app-search d-none d-lg-block events-input-search"
            onSubmit={(e) => e.preventDefault()}
            style={{ marginLeft: "15px", width: "40%" }}
          >
            <div className="position-relative">
              <input
                type={"text"}
                value={search}
                className="form-control"
                placeholder="Pesquisar..."
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="bx bx-search-alt" />
            </div>
          </form>
          <div className="mobile-btn-section" style={{ width: "100%" }}>
            <Row className="mobile-btns-top">
              <form
                className="app-search"
                style={{ padding: "0px" }}
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="position-relative">
                  <input
                    type={"text"}
                    value={search}
                    className="form-control"
                    placeholder="Pesquisar..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <span className="bx bx-search-alt" />
                </div>
              </form>
            </Row>
          </div>
        </div>
        <ModalBody>
          <div className="table-responsive" style={{ margin: "0px 0" }}>
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>

              <tbody>
                {dataList.length > 0 &&
                  dataList.map((e, i) => (
                    <tr key={i}>
                      <td>{e.loginName}</td>
                      <td>{e.loginEmail}</td>
                      <td>
                        <span
                          className="tagDriver"
                          style={{
                            whiteSpace: "nowrap",
                            backgroundColor:
                              e?.newStatus == "Embarcado"
                                ? "green"
                                : e.newStatus == "Desembarcado"
                                ? "red"
                                : e.newStatus == "Não Embarcou"
                                ? "orange"
                                : "transparent",
                          }}
                        >
                          {e?.newStatus ? e.newStatus : ""}
                        </span>
                      </td>
                      <td>
                        <i
                          className="mdi mdi-eye"
                          style={{
                            color: "blue",
                            fontSize: 17,
                            cursor: "pointer",
                            marginRight: 5,
                          }}
                          onClick={() => {
                            getOneParticipant(e.loginId);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          <div style={{ marginTop: 10 }}>
            <Pagination
              data={[]}
              search={search}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              countData={countData}
              maxItemPerPage={itemsPerPage}
              setMaxItemPerPage={setItemsPerPage}
              maxPageButtonsToShow={3}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
